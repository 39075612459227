body {
	background: url(../../img/bg.png);
	padding-top: 78px;
}

table.table tbody tr td {
	vertical-align: middle;
}

table.table thead tr th {
    text-transform: uppercase;
}

.navbar .icon-margin svg {
    margin-right: 6px;
}

.btn .icon-margin svg {
    margin-right: 8px;
}

.navbar {
    font-weight: 700;
}

.panel-title, .modal-title {
    text-transform: uppercase;
    font-weight: 700;
}

.panel-title .icon-margin svg {
    margin-right: 8px;
}

.list-group-item .icon-margin svg {
    margin-right: 8px;
}

.panel-danger {
    border-color: #480101;
}

.panel-danger .panel-heading {
    border-color: #480101;
    background-color: maroon;
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-color: #480101;
}

label {
    text-transform: uppercase;
    font-size: 14px;
}

.btn {
    text-transform: uppercase;
    font-weight: 700;
}

p.social-icons a {
    font-size: 36px;
    margin-right: 16px;
}

.texto-contenido {
    font-size: 18px;
}
